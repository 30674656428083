import React from "react";
import { Facebook, Instagram } from "lucide-react";

type FooterProps = {
  theme: "light" | "dark";
};

export default function Footer({ theme }: FooterProps) {
  return (
    <footer className="bg-gray-100 dark:bg-gray-800 py-12">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <ContactInfo />
          <LegalLinks />
          <SocialLinks />
          <LogoAndCopyright theme={theme} />
        </div>
      </div>
    </footer>
  );
}

function ContactInfo() {
  return (
    <div>
      <h3 className="text-lg font-semibold text-[#fb4414] dark:text-white mb-4">
        Contacto
      </h3>
      <address className="not-italic text-gray-600 dark:text-gray-300">
        <p>Akadamian, S.C.</p>
        <p>Carrer Torres Quevedo, 97</p>
        <p>03204 - Elche</p>
        <p>Alicante</p>
      </address>
      <p className="mt-2">
        <a
          href="mailto:hola@akadamian.com"
          className="text-[#fb4414] hover:underline"
        >
          hola@akadamian.com
        </a>
      </p>
    </div>
  );
}

function LegalLinks() {
  return (
    <div>
      <h3 className="text-lg font-semibold text-[#fb4414] dark:text-white mb-4">
        Enlaces legales
      </h3>
      <ul className="space-y-2">
        <li>
          <a
            href="/politica-cookies"
            className="text-gray-600 dark:text-gray-300 hover:text-[#fb4414] dark:hover:text-[#fb4414]"
          >
            Política de cookies
          </a>
        </li>
        <li>
          <a
            href="/politica-privacidad"
            className="text-gray-600 dark:text-gray-300 hover:text-[#fb4414] dark:hover:text-[#fb4414]"
          >
            Política de privacidad
          </a>
        </li>
        <li>
          <a
            href="/aviso-legal"
            className="text-gray-600 dark:text-gray-300 hover:text-[#fb4414] dark:hover:text-[#fb4414]"
          >
            Aviso legal
          </a>
        </li>
      </ul>
    </div>
  );
}

function SocialLinks() {
  return (
    <div>
      <h3 className="text-lg font-semibold text-[#fb4414] dark:text-white mb-4">
        Redes sociales
      </h3>
      <div className="flex space-x-4">
        <SocialIcon
          href="https://www.facebook.com/profile.php?id=61566840567972"
          Icon={Facebook}
          label="Facebook"
        />
        <SocialIcon
          href="https://www.instagram.com/akadamian_elx"
          Icon={Instagram}
          label="Instagram"
        />
      </div>
    </div>
  );
}

type SocialIconProps = {
  href: string;
  Icon: React.ElementType;
  label: string;
};

function SocialIcon({ href, Icon, label }: SocialIconProps) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" aria-label={label}>
      <Icon className="h-6 w-6 text-gray-600 dark:text-gray-300 hover:text-[#fb4414] dark:hover:text-[#fb4414]" />
    </a>
  );
}

function LogoAndCopyright({ theme }: FooterProps) {
  const currentYear = new Date().getFullYear(); // Obtiene el año actual
  return (
    <div>
      <img
        src={theme === "dark" ? "/logo_dark.svg" : "/logo_light.svg"}
        alt="Logo de Akadamian"
        className="h-12 w-36 object-contain"
      />
      <p className="mt-4 text-sm text-gray-600 dark:text-gray-300">
        &copy; {currentYear} Akadamian. Todos los derechos reservados.
      </p>
    </div>
  );
}
