import React from "react";
import Home from "./pages/Home";
import LegalNotice from "./pages/LegalNotice";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiesPolicy from "./pages/CookiesPolicy";
import { Route, Routes } from "react-router-dom";
import NotFound from "./pages/NotFound";

type MainProps = {
  theme: "light" | "dark";
};

export default function MainContent({ theme }: MainProps) {
  return (
    <main className="flex-grow">
      <Routes>
        <Route path="/" element={<Home theme={theme} />} />
        <Route path="/aviso-legal" element={<LegalNotice />} />
        <Route path="/politica-privacidad" element={<PrivacyPolicy />} />
        <Route path="/politica-cookies" element={<CookiesPolicy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </main>
  );
}
