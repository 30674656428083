export default function NotFound() {
  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 text-center">
        <div className="space-y-4">
          <h1 className="text-6xl font-extrabold text-gray-900 dark:text-gray-100">
            404
          </h1>
          <h2 className="text-3xl font-bold text-gray-900 dark:text-gray-100">
            Página no encontrada
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-400">
            Lo sentimos, no pudimos encontrar la página que estás buscando.
          </p>
        </div>
      </div>
    </div>
  );
}
