import React, { useEffect, useRef, useState } from "react";
import { Moon, Sun, Menu } from "lucide-react";
import NavLinks from "./NavLinks";
import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

type HeaderProps = {
  theme: "light" | "dark";
  toggleTheme: () => void;
};

export default function Header({ theme, toggleTheme }: HeaderProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const isHomePage = location.pathname === "/";

  return (
    <header className="sticky top-0 z-50 bg-white bg-opacity-90 dark:bg-[#3c2c54] dark:bg-opacity-90 shadow-md">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex-shrink-0">
            <HashLink
              to="/#bienvenida"
              smooth
              aria-label="Ir a la página de inicio"
            >
              <img
                src={theme === "dark" ? "/logo_dark.svg" : "/logo_light.svg"}
                alt="Logo de Akadamian"
                className="h-12 w-36 object-contain"
              />
            </HashLink>
          </div>
          <nav className="hidden md:flex items-center space-x-4">
            <NavLinks isHomePage={isHomePage} />
          </nav>
          <div className="flex items-center">
            <button
              onClick={toggleTheme}
              className="p-2 rounded-full bg-gray-200 dark:bg-gray-800 text-[#fb4414] dark:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-[#fb4414] dark:focus:ring-offset-[#3c2c54] dark:focus:ring-white"
              aria-label={`Cambiar a modo ${
                theme === "dark" ? "claro" : "oscuro"
              }`}
            >
              {theme === "dark" ? (
                <Sun className="h-5 w-5" />
              ) : (
                <Moon className="h-5 w-5" />
              )}
            </button>
            <button
              onClick={toggleMenu}
              className="ml-4 md:hidden p-2 rounded-md text-[#fb4414] dark:text-white hover:bg-gray-200 dark:hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-[#fb4414] dark:focus:ring-offset-[#3c2c54] dark:focus:ring-white"
              aria-label="Abrir menú"
              aria-expanded={isMenuOpen}
            >
              <Menu className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div ref={menuRef}>
          <nav className="md:hidden bg-white dark:bg-[#3c2c54] shadow-md">
            <ul className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <NavLinks mobile isHomePage={isHomePage} />
            </ul>
          </nav>
        </div>
      )}
    </header>
  );
}
