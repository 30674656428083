import React from "react";

var klaroConfig = {
  cookieExpiresAfterDays: 365,
  privacyPolicy: "/politica-cookies",
  acceptAll: true,
  services: [
    {
      name: "cookies-consent",
      default: true,
      title: "Consentimiento de cookies",
      purposes: ["Cookies propias"],
      description:
        "Necesaria para recordar su selección sobre las cookies que permite",
      cookies: [/^klaro/i],
      required: true, // Hace que sea obligatorio y no pueda desactivarse
      optOut: false, // Impide que el usuario lo deshabilite
      onlyOnce: true, // Se ejecuta una vez y no se vuelve a cargar
    },
    {
      name: "google-analytics",
      default: false,
      title: "Google Analytics",
      purposes: ["Cookies de terceros"],
      description:
        "Servicio de Google para fines analíticos con el fin de mejorar la experiencia de usuario",
      cookies: [/^_ga(_.*)?/],
      onAccept: function () {
        loadGoogleAnalytics();
      },
    },
  ],
  translations: {
    es: {
      decline: "Permitir solo esenciales",
      ok: "Aceptar todas",
      consentNotice: {
        title: "Utilizamos cookies",
        description:
          "Utilizamos cookies propias y de terceros para mejorar nuestros servicios y conocer sus preferencias mediante el análisis de sus hábitos de navegación. Consulta nuestra {privacyPolicy}. Puedes aceptar todas las cookies pulsando el botón 'Aceptar' o configurarlas o rechazar su uso pulsando el botón 'Configurar'.",
        learnMore: "Configurar",
      },
      consentModal: {
        title: "Servicios que nos gustaría utiliar",
        description:
          "A continuación le mostramos las cookies que usamos en nuestro sitio. Alguna de ellas podrías ser requerida para el funcionamiento del sistema y por ese motivo no podrá desactivarla.", // main description
        privacyPolicy: {
          text: "Para más información puede leer nuestra {privacyPolicy}.",
        },
      },
      privacyPolicy: {
        name: "Política de cookies",
        text: "Lea nuestra {privacyPolicy} para más información.",
      },
      acceptSelected: "Aceptar",
    },
  },
  callback: function (consent: string, service: { name: string }) {
    console.log(
      "User consent for service " + service.name + ": consent=" + consent
    );
  },
};

const loadGoogleAnalytics = () => {
  const script = document.createElement("script");
  script.src = "https://www.googletagmanager.com/gtag/js?id=G-X32H6305D4";
  script.async = true;
  document.head.appendChild(script);

  const inlineScript = document.createElement("script");
  inlineScript.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-X32H6305D4');
  `;
  document.head.appendChild(inlineScript);
};

const Cookies = class extends React.Component {
  componentDidMount() {
    // Initialize Klaro cookie consent
    var Klaro = require("klaro");
    // we assign the Klaro module to the window, so that we can access it in JS

    // @ts-ignore
    window.klaro = Klaro;

    // we set up Klaro with the config
    Klaro.setup(klaroConfig);
  }

  render() {
    return <></>;
  }
};

export default Cookies;
