import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import MainContent from "./components/MainContent";
import Footer from "./components/Footer";
import WhatsAppButton from "./components/WhatsAppButton";
import { BrowserRouter as Router } from "react-router-dom";

type Theme = "light" | "dark";

export default function App() {
  const [mounted, setMounted] = useState(false);
  const [theme, setTheme] = useState<Theme>("light");

  useEffect(() => {
    setMounted(true);
    const savedTheme = localStorage.getItem("theme") as Theme | null;
    if (savedTheme) {
      setTheme(savedTheme);
    }
  }, []);

  useEffect(() => {
    if (mounted) {
      localStorage.setItem("theme", theme);
      document.documentElement.classList.toggle("dark", theme === "dark");
    }
  }, [theme, mounted]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "dark" ? "light" : "dark"));
  };

  if (!mounted) {
    return null;
  }

  return (
    <Router>
      <div
        className={`min-h-screen flex flex-col ${
          theme === "dark" ? "dark bg-[#3c2c54]" : "bg-white"
        } transition-colors duration-300`}
      >
        <Header theme={theme} toggleTheme={toggleTheme} />
        <MainContent theme={theme} />
        <Footer theme={theme} />
        <WhatsAppButton
          phoneNumber="34722862842"
          message="Hola, me gustaría obtener más información sobre Akadamian."
        />
      </div>
    </Router>
  );
}
