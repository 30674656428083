import React, { useState, useEffect, useRef } from "react";
import { CheckCircle, XCircle, X, AlertCircle } from "lucide-react";
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile";

type ContactProps = {
  theme: "light" | "dark";
};
type ContactFormProps = {
  theme: "light" | "dark";
};

export default function Contact({ theme }: ContactProps) {
  return (
    <section id="contacto" className="py-16 bg-white dark:bg-[#3c2c54]">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-[#fb4414] dark:text-white mb-8 text-center">
          Contacto
        </h2>
        <div className="flex justify-center mb-8">
          <div className="w-16 h-1 bg-[#fb4414] dark:bg-white rounded-full"></div>
        </div>
        <div className="flex flex-col lg:flex-row gap-8">
          <div className="lg:w-1/2">
            <ContactForm theme={theme} />
          </div>
          <div className="lg:w-1/2">
            <LocationMap />
          </div>
        </div>
      </div>
    </section>
  );
}

function ContactForm({ theme }: ContactFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [turnstilePassed, setTurnstilePassed] = useState(false);
  const refTurnstile = useRef<TurnstileInstance>(null);
  const cloudFlareTurnstilePublicKey =
    process.env.REACT_APP_CLOUDFLARE_TURNSTILE_PUBLIC_KEY ||
    "0x4AAAAAAAxuxLhIuVCTwXDS";
  const [toast, setToast] = useState({
    message: "",
    isSuccess: false,
    isVisible: false,
  });
  const formRef = useRef<HTMLFormElement>(null);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const validateForm = (formData: { [key: string]: string }) => {
    const newErrors: { [key: string]: string } = {};
    if (!formData.nombre.trim()) {
      newErrors.nombre = "El nombre es obligatorio";
    }
    if (!formData.message.trim()) {
      newErrors.message = "El mensaje es obligatorio";
    }
    if (!formData.email && !formData.phone) {
      newErrors.contact = "Debes proporcionar al menos un email o un teléfono";
    }
    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "El email no es válido";
    }
    if (formData.phone && !/^\d{9}$/.test(formData.phone)) {
      newErrors.phone = "El teléfono debe tener 9 dígitos";
    }
    if (!formData.privacidad) {
      newErrors.privacidad =
        "Debes aceptar la política de privacidad y el aviso legal";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setToast((prev) => ({ ...prev, isVisible: false })); // Hide any existing toast
    const form = e.target as HTMLFormElement;

    const formData = {
      nombre: form.nombre.value,
      email: form.email.value,
      phone: form.phone.value,
      message: form.message.value,
      privacidad: form.privacidad.checked,
    };

    if (!validateForm(formData)) {
      setIsLoading(false);
      return;
    }

    const payload = {
      name: form.nombre.value,
      email: form.email.value?.length > 0 ? form.email.value : undefined,
      phone: form.phone.value?.length > 0 ? form.phone.value : undefined,
      message: form.message.value,
      privacy: form.privacidad.checked,
      turnstileToken: form["cf-turnstile-response"].value ?? "",
    };
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.status === 201) {
        setToast({
          message:
            "Se ha recibido la información con éxito, en breve nos pondremos en contacto contigo.",
          isSuccess: true,
          isVisible: true,
        });
        if (formRef.current) {
          formRef.current.reset(); // Clear the form
          refTurnstile.current?.reset(); // Reset the Turnstile component
        }
        setErrors({}); // Clear any existing errors
      } else {
        setToast({
          message:
            "No ha sido posible atender la petición, prueba de nuevo más tarde.",
          isSuccess: false,
          isVisible: true,
        });
      }
    } catch (error) {
      console.error("Error al enviar el mensaje:", error);
      setToast({
        message:
          "No ha sido posible atender la petición, prueba de nuevo más tarde.",
        isSuccess: false,
        isVisible: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (toast.isVisible) {
      const timer = setTimeout(() => {
        setToast((prev) => ({ ...prev, isVisible: false }));
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [toast.isVisible]);

  const closeToast = () => {
    setToast((prev) => ({ ...prev, isVisible: false }));
  };

  return (
    <>
      <form
        ref={formRef}
        className="space-y-4"
        onSubmit={handleSubmit}
        noValidate
      >
        <InputField
          label="Nombre *"
          id="nombre"
          type="text"
          required
          error={errors.nombre}
        />
        <InputField
          label="Email"
          id="email"
          type="email"
          error={errors.email}
        />
        <InputField
          label="Teléfono"
          id="phone"
          type="tel"
          error={errors.phone}
        />
        {errors.contact && (
          <p className="text-red-500 text-sm flex items-center">
            <AlertCircle className="w-4 h-4 mr-2" />
            {errors.contact}
          </p>
        )}
        <TextAreaField
          label="Mensaje *"
          id="message"
          required
          error={errors.message}
        />
        <PrivacyCheckbox error={errors.privacidad} />
        <Turnstile
          id="turnstile-1"
          ref={refTurnstile}
          siteKey={cloudFlareTurnstilePublicKey}
          options={{
            theme: theme === "dark" ? "dark" : "light",
            size: "normal",
            language: "es",
          }}
          onSuccess={() => setTurnstilePassed(true)}
        />
        <p className="text-sm text-gray-600 dark:text-gray-400">
          * Campos obligatorios. Debes proporcionar al menos un email o un
          teléfono.
        </p>
        <button
          type="submit"
          className="w-full bg-[#fb4414] text-white font-bold py-2 px-4 rounded hover:bg-[#d63a0f] transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#fb4414] disabled:opacity-50"
          disabled={isLoading || !turnstilePassed}
        >
          {isLoading ? (
            <>
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white inline"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Enviando...
            </>
          ) : (
            "Enviar mensaje"
          )}
        </button>
      </form>

      {toast.isVisible && (
        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-50 w-full max-w-md px-4">
          <div
            className={`flex items-center p-4 rounded-lg shadow-lg ${
              toast.isSuccess ? "bg-green-500" : "bg-red-500"
            } text-white transition-all duration-300 ease-in-out`}
            style={{
              boxShadow:
                "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
            }}
          >
            <div className="flex-shrink-0 mr-3">
              {toast.isSuccess ? (
                <CheckCircle className="w-6 h-6" />
              ) : (
                <XCircle className="w-6 h-6" />
              )}
            </div>
            <p className="flex-grow text-sm md:text-base">{toast.message}</p>
            <button
              onClick={closeToast}
              className="ml-3 p-1 rounded-full hover:bg-white hover:bg-opacity-20 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-white"
              aria-label="Cerrar notificación"
            >
              <X className="w-4 h-4" />
            </button>
          </div>
        </div>
      )}
    </>
  );
}

type InputFieldProps = {
  label: string;
  id: string;
  type: string;
  required?: boolean;
  error?: string;
};

function InputField({ label, id, type, required, error }: InputFieldProps) {
  return (
    <div>
      <label
        htmlFor={id}
        className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
      >
        {label}
      </label>
      <input
        type={type}
        id={id}
        name={id}
        className={`w-full px-3 py-2 border ${
          error ? "border-red-500" : "border-gray-300"
        } rounded-md shadow-sm focus:outline-none focus:ring-[#fb4414] focus:border-[#fb4414] dark:bg-gray-700 dark:border-gray-600 dark:text-white transition-colors duration-200`}
        required={required}
      />
      {error && (
        <p className="mt-1 text-red-500 text-sm flex items-center">
          <AlertCircle className="w-4 h-4 mr-2" />
          {error}
        </p>
      )}
    </div>
  );
}

type TextAreaFieldProps = {
  label: string;
  id: string;
  required?: boolean;
  error?: string;
};

function TextAreaField({ label, id, required, error }: TextAreaFieldProps) {
  return (
    <div>
      <label
        htmlFor={id}
        className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
      >
        {label}
      </label>
      <textarea
        id={id}
        name={id}
        rows={4}
        className={`w-full px-3 py-2 border ${
          error ? "border-red-500" : "border-gray-300"
        } rounded-md shadow-sm focus:outline-none focus:ring-[#fb4414] focus:border-[#fb4414] dark:bg-gray-700 dark:border-gray-600 dark:text-white transition-colors duration-200`}
        required={required}
      ></textarea>
      {error && (
        <p className="mt-1 text-red-500 text-sm flex items-center">
          <AlertCircle className="w-4 h-4 mr-2" />
          {error}
        </p>
      )}
    </div>
  );
}

type PrivacyCheckboxProps = {
  error?: string;
};

function PrivacyCheckbox({ error }: PrivacyCheckboxProps) {
  return (
    <div className="flex flex-col">
      <div className="flex items-start">
        <div className="flex items-center h-5">
          <input
            type="checkbox"
            id="privacidad"
            name="privacidad"
            className={`h-4 w-4 text-[#fb4414] focus:ring-[#fb4414] border-gray-300 rounded ${
              error ? "border-red-500" : ""
            }`}
          />
        </div>
        <div className="ml-3 text-sm">
          <label
            htmlFor="privacidad"
            className="font-medium text-gray-700 dark:text-gray-300"
          >
            He leído y acepto la{" "}
            <a
              href="/politica-privacidad"
              className="text-[#fb4414] hover:underline"
            >
              política de privacidad
            </a>{" "}
            y el{" "}
            <a href="/aviso-legal" className="text-[#fb4414] hover:underline">
              aviso legal
            </a>
            . *
          </label>
        </div>
      </div>
      {error && (
        <p className="mt-1 text-red-500 text-sm flex items-center">
          <AlertCircle className="w-4 h-4 mr-2" />
          {error}
        </p>
      )}
    </div>
  );
}

function LocationMap() {
  return (
    <div className="mb-6">
      <h3 className="text-xl font-semibold text-[#fb4414] dark:text-white mb-2">
        Nuestra ubicación
      </h3>
      <iframe
        width="100%"
        height="350"
        src="https://www.openstreetmap.org/export/embed.html?bbox=-0.7082882523536682%2C38.256392596957696%2C-0.7060351967811586%2C38.25783322270264&amp;layer=mapnik&amp;marker=38.25711291340054%2C-0.7071617245674133"
        style={{ border: "1px solid black" }}
        title="Mapa de ubicación de la Academia"
      ></iframe>
      <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
        <a
          href="https://www.openstreetmap.org/#map=19/38.257131/-0.707489&amp;layers=N"
          className="text-[#fb4414] hover:underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ver el mapa más grande
        </a>
      </p>
    </div>
  );
}
