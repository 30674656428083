import React from "react";

export default function LegalNotice() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800 py-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold text-gray-900 dark:text-white mb-8 text-center">
          Aviso Legal
        </h1>

        <div className="bg-white dark:bg-gray-800 shadow-lg rounded-2xl overflow-hidden">
          <div className="px-6 py-8 sm:p-10 space-y-6 text-justify">
            <section>
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
                Datos Identificativos
              </h2>
              <ul className="list-none space-y-2 text-gray-700 dark:text-gray-300">
                <li>
                  <span className="font-semibold">Responsable:</span> Akadamian,
                  S.C.
                </li>
                <li>
                  <span className="font-semibold">NIF:</span> J70901244
                </li>
                <li>
                  <span className="font-semibold">Domicilio social:</span> Calle
                  Torres Quevedo, 97 03204 Elx/Elche - (Alicante)
                </li>
                <li>
                  <span className="font-semibold">
                    Dirección de correo electrónico:
                  </span>{" "}
                  <a
                    href="mailto:administracion@akadamian.com"
                    className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600"
                  >
                    administracion@akadamian.com
                  </a>
                </li>
                <li>
                  <span className="font-semibold">Dirección web:</span>{" "}
                  <a
                    href="/"
                    className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600"
                  >
                    www.akadamian.com
                  </a>
                </li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
                1. INFORMACIÓN LEGAL
              </h2>
              <p className="text-gray-700 dark:text-gray-300">
                Bienvenido al Sitio Web de Akadamian Centros de Estudios, el
                cual ha sido diseñado con el objeto de facilitarle información
                general acerca de la actividad y los servicios que ofrece. Los
                términos y condiciones que serán aplicables durante su
                navegación por este Sitio Web se encuentran recogidos en el
                apartado "Condiciones Generales de Uso" del presente documento,
                de conformidad con la normativa legal vigente. Se recuerda que
                el responsable podría modificar en cualquier momento estas
                condiciones de manera unilateral, por lo que es recomendable que
                las visite de forma periódica a fin de estar debidamente
                informado de posibles cambios realizados.
              </p>
              <p className="text-gray-700 dark:text-gray-300 mt-4">
                El acceso al Sitio Web es gratuito y no exige previa suscripción
                o registro, salvo en lo relativo al coste de la conexión a
                través de la red de telecomunicaciones suministrada por el
                proveedor de acceso contratado por los usuarios. La utilización
                del Sitio Web le atribuye la condición de Usuario e implica la
                aceptación de todas las condiciones incluidas en este Aviso
                Legal.
              </p>
              <p className="text-gray-700 dark:text-gray-300 mt-4">
                Para garantizar que su navegación se ajuste a los criterios de
                transparencia, claridad y sencillez, se informa al Usuario de
                que cualquier duda, sugerencia o consulta sobre el presente
                Aviso Legal será recibida y tramitada a través de la dirección
                de correo electrónico{" "}
                <a
                  href="mailto:administracion@akadamian.com"
                  className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600"
                >
                  administracion@akadamian.com
                </a>
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
                2. CONDICIONES GENERALES DE USO
              </h2>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                2.1 COMPROMISOS Y OBLIGACIONES DEL USUARIO:
              </h3>
              <p className="text-gray-700 dark:text-gray-300">
                El usuario se compromete a utilizar los servicios y contenidos
                del Sitio Web, de conformidad con la legislación aplicable y las
                presentes Condiciones Generales de Uso, así como con demás
                condiciones, reglamentos o instrucciones que en su caso pudieran
                resultar de aplicación en el presente Aviso Legal.
              </p>
              <p className="text-gray-700 dark:text-gray-300 mt-4">
                El Usuario responderá frente a Akadamian, S.C. o frente a
                terceros de cualesquiera daños y perjuicios que pudieran
                causarse como consecuencia del incumplimiento de esta
                obligación.
              </p>
              <p className="text-gray-700 dark:text-gray-300 mt-4">
                Queda expresamente prohibido el uso del Sitio Web con fines
                lesivos de bienes o intereses del responsable o de terceros o
                que de cualquier otra forma sobrecarguen, dañen o inutilicen las
                redes, servidores y demás equipos informáticos (hardware) o
                productos y aplicaciones informáticas (software) de los mismos.
              </p>

              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2 mt-6">
                2.2 EXCLUSIÓN DE RESPONSABILIDAD:
              </h3>
              <p className="text-gray-700 dark:text-gray-300">
                Akadamian, S.C. no se responsabiliza ni garantiza que el acceso
                a este Sitio Web esté libre de error o no pueda ser interrumpido
                por eventuales errores de programación u otras causas de fuerza
                mayor.
              </p>
              <p className="text-gray-700 dark:text-gray-300 mt-4">
                El titular adopta medidas de seguridad razonablemente adecuadas
                para detectar la existencia de virus. No obstante, dado que las
                medidas de seguridad de los sistemas informáticos no son
                plenamente fiables, no se garantiza la ausencia de virus ni de
                otros elementos en los contenidos que puedan producir
                alteraciones en su sistema informático (software y hardware) o
                en los documentos electrónicos y ficheros almacenados en su
                sistema informático, por lo que corresponde al Usuario disponer
                de herramientas adecuadas para su detección.
              </p>
              <p className="text-gray-700 dark:text-gray-300 mt-4">
                De igual manera, en ningún caso será responsable por las
                pérdidas, daños o perjuicios de cualquier naturaleza, directos o
                indirectos, que surjan por el acceso y uso del Sitio Web o sus
                contenidos, ni por aquellos que pudiesen ocasionarse al Usuario
                por un uso inadecuado de este Sitio Web o sus contenidos. En
                particular, no se responsabiliza en modo alguno de las caídas,
                interrupciones, falta o defecto de las comunicaciones ni de los
                daños informáticos que pudieran ocasionar al usuario visitante
                el acceso a los contenidos de este sitio.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
                3. PROTECCIÓN DE DATOS PERSONALES:
              </h2>
              <p className="text-gray-700 dark:text-gray-300">
                La información sobre el tratamiento de sus datos personales se
                encuentra detallada en la{" "}
                <a
                  href="/politica-privacidad"
                  className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600"
                >
                  Política de Privacidad
                </a>
                .
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
                4. PROPIEDAD INDUSTRIAL E INTELECTUAL
              </h2>
              <p className="text-gray-700 dark:text-gray-300">
                Todos los contenidos del Sitio Web y en especial, diseños,
                textos, imágenes, logos, iconos, botones, software, nombres
                comerciales, marcas o cualesquiera otros signos de utilización
                industrial y/o comercial, son propiedad intelectual de
                Akadamian, S.C. , sin que puedan entenderse cedidos al Usuario
                ninguno de los derechos de explotación sobre los mismos más allá
                de lo estrictamente necesario para el correcto uso del Sitio. El
                Usuario se compromete a no reproducir, copiar, distribuir, poner
                a disposición o comunicar públicamente de cualquier otro modo,
                transformar o modificar tales contenidos salvo en los casos
                autorizados por la ley o consentidos de forma expresa por
                Akadamian, S.C. o, en su caso, por quien ostente la titularidad
                de los derechos de explotación. En ningún caso, el acceso al
                Sitio Web implica ningún tipo de renuncia, transmisión, licencia
                o cesión total ni parcial de dichos derechos, salvo que se
                establezca expresamente lo contrario.
              </p>
              <p className="text-gray-700 dark:text-gray-300 mt-4">
                El Usuario deberá abstenerse de reproducir o copiar para uso
                privado tales contenidos que puedan ser considerados como
                Software o Base de Datos de conformidad con la legislación
                vigente en materia de propiedad intelectual, así como su
                comunicación pública o puesta a disposición de terceros cuando
                estos actos impliquen necesariamente la reproducción por parte
                del Usuario o de un tercero.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
                5. LEY APLICABLE:
              </h2>
              <p className="text-gray-700 dark:text-gray-300">
                La ley aplicable en caso de disputa o conflicto de
                interpretación de los términos y condiciones de este Sitio Web,
                así como cualquier cuestión relacionada con los servicios
                ofertados en el mismo, será la ley española.
              </p>
              <p className="text-gray-700 dark:text-gray-300 mt-4">
                Toda controversia o disputa legal que pueda suscitarse entre el
                usuario y la empresa por el uso de este Sitio Web, queda
                sometida a la vía judicial en los Juzgados y Tribunales que
                correspondan de conformidad con las reglas de competencia
                legalmente establecidas en la Ley 1/2000, de 7 de enero, de
                Enjuiciamiento Civil.
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
