import AboutUs from "../sections/AboutUs";
import Contact from "../sections/Contact";
import FAQ from "../sections/FAQ";
import Welcome from "../sections/Welcome";

type HomeProps = {
  theme: "light" | "dark";
};

export default function Home({ theme }: HomeProps) {
  return (
    <>
      <Welcome />
      <AboutUs theme={theme} />
      <FAQ />
      <Contact theme={theme} />
    </>
  );
}
