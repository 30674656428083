import React from "react";

type AboutProps = {
  theme: "light" | "dark";
};

export default function AboutUs({ theme }: AboutProps) {
  return (
    <section id="conocenos" className="py-16 bg-white dark:bg-[#3c2c54]">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-[#fb4414] dark:text-white mb-4 text-center">
          Conócenos
        </h2>

        <div className="flex justify-center mb-8">
          <div className="w-16 h-1 bg-[#fb4414] dark:bg-white rounded-full"></div>
        </div>

        <div className="mb-12 flex flex-col md:flex-row items-center md:items-start gap-8">
          <div className="md:w-1/3">
            <img
              src={theme === "dark" ? "/about-dark.svg" : "/about-light.svg"}
              alt="Akadamian centro de estudios"
              className="rounded-lg w-full h-auto"
            />
          </div>
          <div className="md:w-2/3">
            <h3 className="text-2xl font-semibold text-[#fb4414] dark:text-white mb-4">
              Akadamian, más que un centro de estudios
            </h3>
            <p className="text-gray-700 dark:text-gray-300 text-lg text-justify">
              Akadamian es un espacio de aprendizaje innovador donde fomentamos
              el crecimiento personal y académico. Nuestro enfoque integral
              combina educación de calidad con desarrollo de habilidades para la
              vida, preparando a nuestros estudiantes para un futuro exitoso.
              Creemos en el potencial de cada individuo y nos esforzamos por
              proporcionar un ambiente de aprendizaje estimulante y de apoyo. En
              Akadamian, entendemos que cada estudiante es único, con sus
              propias fortalezas y áreas de mejora. Por eso, nuestro equipo de
              educadores altamente cualificados se dedica a proporcionar una
              atención personalizada, adaptando nuestros métodos de enseñanza
              para satisfacer las necesidades individuales de cada alumno.
              Nuestro objetivo es no solo impartir conocimientos, sino también
              inspirar una pasión por el aprendizaje que durará toda la vida.
            </p>
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-8">
          <AboutSection
            title="Akadamian presencial"
            content="En nuestras instalaciones, ofrecemos una experiencia educativa inmersiva y personalizada. Contamos con aulas modernas, tecnología de vanguardia y un equipo docente altamente cualificado. Nuestros programas presenciales fomentan la interacción directa, el trabajo en equipo y el aprendizaje práctico, creando un ambiente estimulante que impulsa el rendimiento académico y el crecimiento personal de cada estudiante. Además, nuestras instalaciones están diseñadas para proporcionar un entorno de aprendizaje óptimo, con espacios dedicados para el estudio individual y en grupo."
            imageUrl={
              theme === "dark"
                ? "/presencial-dark.svg"
                : "/presencial-light.svg"
            }
            imageAlt="Akadamian presencial"
          />
          <AboutSection
            title="Akadamian Online (Próximamente)"
            content="Nuestra plataforma de aprendizaje en línea ofrece flexibilidad y accesibilidad sin comprometer la calidad educativa. Con cursos interactivos, recursos multimedia y sesiones en vivo, Akadamian Online permite a los estudiantes aprender a su propio ritmo desde cualquier lugar. Utilizamos las últimas herramientas digitales para crear una experiencia de aprendizaje envolvente, facilitando la colaboración virtual y el seguimiento personalizado del progreso de cada alumno. Nuestro sistema de aprendizaje adaptativo ajusta el contenido y el ritmo según el desempeño individual, asegurando que cada estudiante reciba el apoyo necesario para alcanzar su máximo potencial."
            imageUrl={
              theme === "dark" ? "/online-dark.svg" : "/online-light.svg"
            }
            imageAlt="Akadamian Online"
          />
        </div>
      </div>
    </section>
  );
}

type AboutSectionProps = {
  title: string;
  content: string;
  imageUrl: string;
  imageAlt: string;
};

function AboutSection({
  title,
  content,
  imageUrl,
  imageAlt,
}: AboutSectionProps) {
  return (
    <div className="flex flex-col h-full">
      <h3 className="text-2xl font-semibold text-[#fb4414] dark:text-white mb-4">
        {title}
      </h3>
      <div className="flex flex-col flex-grow">
        <p className="text-gray-700 dark:text-gray-300 text-lg mb-4 flex-grow text-justify">
          {content}
        </p>
        <div className="mt-auto flex justify-center">
          <img src={imageUrl} alt={imageAlt} className=" w-2/3 h-auto" />
        </div>
      </div>
    </div>
  );
}
