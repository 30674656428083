import React from "react";

export default function CookiePolicy() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800 py-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold text-gray-900 dark:text-white mb-8 text-center">
          Política de Cookies
        </h1>

        <div className="bg-white dark:bg-gray-800 shadow-lg rounded-2xl overflow-hidden">
          <div className="px-6 py-8 sm:p-10 space-y-6 text-justify">
            <section>
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
                1. QUÉ SON LAS COOKIES
              </h2>
              <p className="text-gray-700 dark:text-gray-300">
                Las cookies son archivos que se pueden descargar en su equipo o
                dispositivo móvil a través de las páginas web. Son herramientas
                que tienen un papel esencial para la prestación de numerosos
                servicios de la sociedad de la información. Entre otros,
                permiten a una página web almacenar y recuperar información
                sobre los hábitos de navegación de un usuario o de su equipo y,
                dependiendo de la información obtenida, se pueden utilizar para
                reconocer al usuario y mejorar el servicio ofrecido.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
                2. TIPOS DE COOKIES
              </h2>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                Según la entidad que las gestione:
              </h3>
              <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 space-y-2">
                <li>
                  Cookies propias: aquellas que se envían al equipo terminal del
                  usuario desde un equipo o dominio gestionado por el propio
                  editor y desde el que se presta el servicio solicitado por el
                  usuario
                </li>
                <li>
                  Cookies de terceros: aquellas que se envían al equipo terminal
                  del usuario desde un equipo o dominio que no es gestionado por
                  el editor, sino por otra entidad que trata los datos obtenidos
                  a través de las cookies
                </li>
              </ul>

              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2 mt-4">
                Según el plazo de tiempo que permanecen almacenadas en el
                navegador del cliente:
              </h3>
              <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 space-y-2">
                <li>
                  Cookies de sesión: son un tipo de cookies diseñadas para
                  recabar y almacenar datos mientras el usuario accede a una
                  página web. Se suelen emplear para almacenar información que
                  solo interesa conservar para la prestación del servicio
                  solicitado por el usuario en una sola ocasión
                </li>
                <li>
                  Cookies persistentes: son un tipo de cookies en el que los
                  datos siguen almacenados en el terminal y pueden ser accedidos
                  y tratados durante un periodo definido por el responsable de
                  la cookie, y que puede ir de unos minutos a varios años
                </li>
              </ul>

              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2 mt-4">
                Según su finalidad:
              </h3>
              <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 space-y-2">
                <li>
                  Cookies técnicas: aquellas que permiten al usuario la
                  navegación a través de una página web, plataforma o aplicación
                  y la utilización de las diferentes opciones o servicios que en
                  ella existan
                </li>
                <li>
                  Cookies de personalización: aquellas que permiten al usuario
                  acceder al servicio con algunas características de carácter
                  general predefinidas en función de una serie de criterios en
                  el terminal del usuario
                </li>
                <li>
                  Cookies de análisis: aquellas que permiten al responsable de
                  las mismas el seguimiento y análisis del comportamiento de los
                  usuarios de los Sitios Web a los que están vinculadas
                </li>
                <li>
                  Cookies publicitarias: aquellas que permiten la gestión, de la
                  forma más eficaz posible, de los espacios publicitarios que,
                  en su caso, el editor haya incluido en la página web
                </li>
                <li>
                  Cookies de publicidad comportamental: aquellas que almacenan
                  información acerca del comportamiento de los usuarios obtenida
                  a través de la observación continuada de sus hábitos de
                  navegación
                </li>
                <li>
                  Cookies de redes sociales externas: aquellas que se utilizan
                  para que los visitantes puedan interactuar con el contenido de
                  diferentes plataformas sociales
                </li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
                3. DERECHOS DE LOS DESTINATARIOS DE SERVICIOS:
              </h2>
              <p className="text-gray-700 dark:text-gray-300">
                En cumplimiento de la Ley 34/2002, de 11 de julio, de servicios
                de la sociedad de la información y de comercio electrónico
                (LSSICE), el responsable se encuentra obligado a obtener el
                consentimiento expreso del Usuario de todas las páginas web que
                usan cookies analíticas y de publicidad y afiliación. Quedan
                exceptuadas del cumplimiento de esta obligación las cookies
                utilizadas con la única finalidad de permitir la comunicación
                entre el equipo del usuario y la red (cookies técnicas) o de
                prestar estrictamente un servicio solicitado de forma expresa
                por el usuario.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
                4. TIPOS DE COOKIES QUE SE UTILIZAN EN ESTE SITIO WEB:
              </h2>
              <p className="text-gray-700 dark:text-gray-300">
                El titular de este Sitio Web utiliza cookies propias necesarias
                para el funcionamiento de la web, y cookies de terceros que
                requieren el consentimiento del usuario:
              </p>

              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2 mt-4">
                COOKIES PROPIAS:
              </h3>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 bg-gray-100 dark:bg-gray-700 text-left text-xs font-medium text-gray-600 dark:text-gray-300 uppercase tracking-wider">
                        Nombre
                      </th>
                      <th className="px-4 py-2 bg-gray-100 dark:bg-gray-700 text-left text-xs font-medium text-gray-600 dark:text-gray-300 uppercase tracking-wider">
                        Finalidad
                      </th>
                      <th className="px-4 py-2 bg-gray-100 dark:bg-gray-700 text-left text-xs font-medium text-gray-600 dark:text-gray-300 uppercase tracking-wider">
                        Duración
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-gray-300">
                        klaro
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-gray-300">
                        Almacena las preferencias de cookies del usuario
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-gray-300">
                        1 año
                      </td>
                    </tr>
                    <tr className="bg-gray-50 dark:bg-gray-900">
                      <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-gray-300">
                        session_id
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-gray-300">
                        Mantiene la sesión del usuario
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-gray-300">
                        Sesión
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2 mt-4">
                COOKIES DE TERCEROS:
              </h3>
              <p className="text-gray-700 dark:text-gray-300">
                Esta web utiliza cookies de Google Analytics y Google Tag
                Manager (GTM). GTM es un sistema de gestión de etiquetas que nos
                permite añadir funcionalidades de terceros a la web, tales como
                análisis web, medición de audiencia y personalización. GTM puede
                añadir y actualizar etiquetas personalizadas y de terceros. A
                continuación, se detallan las cookies de terceros, sin perjuicio
                de que puedan sufrir modificaciones. La aceptación o negación al
                uso de estás cookies implica aceptarlas o rechazarlas todas
                ellas en su conjunto.
              </p>
              <p className="text-gray-700 dark:text-gray-300 mt-4">
                Tenga en cuenta que, si acepta las cookies de terceros, deberá
                eliminarlas desde las opciones del navegador o desde el sistema
                ofrecido por el propio tercero. Para acceder a la política de
                cookies de Google Analytics, pinche{" "}
                <a
                  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es"
                  className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600"
                >
                  aquí
                </a>
                . Para acceder a la política de privacidad de GTM, pinche{" "}
                <a
                  href="https://support.google.com/tagmanager/answer/9323295?hl=es"
                  className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600"
                >
                  aquí
                </a>
                .
              </p>
              <div className="overflow-x-auto mt-4">
                <table className="min-w-full bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 bg-gray-100 dark:bg-gray-700 text-left text-xs font-medium text-gray-600 dark:text-gray-300 uppercase tracking-wider">
                        Nombre
                      </th>
                      <th className="px-4 py-2 bg-gray-100 dark:bg-gray-700 text-left text-xs font-medium text-gray-600 dark:text-gray-300 uppercase tracking-wider">
                        Finalidad
                      </th>
                      <th className="px-4 py-2 bg-gray-100 dark:bg-gray-700 text-left text-xs font-medium text-gray-600 dark:text-gray-300 uppercase tracking-wider">
                        Duración
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-gray-300">
                        _ga
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-gray-300">
                        Distinguir usuarios únicos
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-gray-300">
                        2 años
                      </td>
                    </tr>
                    <tr className="bg-gray-50 dark:bg-gray-900">
                      <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-gray-300">
                        _gid
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-gray-300">
                        Distinguir usuarios
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-gray-300">
                        24 horas
                      </td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-gray-300">
                        _gat
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-gray-300">
                        Limitar el porcentaje de solicitudes
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-gray-300">
                        1 minuto
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
                5. SERVICIOS DE TERCEROS
              </h2>
              <p className="text-gray-700 dark:text-gray-300">
                Para ofrecerle el mejor servicio posible, esta página web enlaza
                con servicios de terceros. Al incluir estos recursos en nuestra
                página, se podrán ejecutar cookies de terceros cuya
                configuración es ajena al titular de la web por estar
                programadas en complementos web sobre los que técnicamente no se
                puede operar. Para gestionar las preferencias deberá acceder a
                la política de privacidad de terceros:
              </p>
              <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 mt-2">
                <li>
                  <a
                    href="https://policies.google.com/technologies/cookies?hl=es"
                    className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600"
                  >
                    Google
                  </a>
                </li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
                6. CÓMO DESHABILITAR LAS COOKIES EN LOS PRINCIPALES NAVEGADORES:
              </h2>
              <p className="text-gray-700 dark:text-gray-300">
                Con carácter general, Usted tiene la posibilidad de permitir,
                bloquear o eliminar las cookies instaladas en su equipo o
                dispositivo móvil mediante la configuración de las opciones del
                navegador instalado en los mismos. No obstante, al desactivar
                cookies, algunos de los servicios podrían dejar de estar
                operativos.
              </p>
              <p className="text-gray-700 dark:text-gray-300 mt-4">
                La forma de deshabilitar las cookies es diferente para cada
                navegador, si quiere ampliar esta información, por favor
                consulte las instrucciones y manuales de su navegador:
              </p>
              <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 mt-2 space-y-2">
                <li>
                  <a
                    href="https://support.google.com/chrome/answer/95647?hl=es"
                    className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600"
                  >
                    Google Chrome
                  </a>
                </li>
                <li>
                  <a
                    href="https://support.microsoft.com/es-es/topic/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                    className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600"
                  >
                    Microsoft Internet Explorer
                  </a>
                </li>
                <li>
                  <a
                    href="https://support.microsoft.com/es-es/windows/microsoft-edge-datos-de-exploraci%C3%B3n-y-privacidad-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd"
                    className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600"
                  >
                    Microsoft Edge
                  </a>
                </li>
                <li>
                  <a
                    href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias"
                    className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600"
                  >
                    Mozilla Firefox
                  </a>
                </li>
                <li>
                  <a
                    href="https://support.apple.com/es-es/guide/safari/sfri11471/mac"
                    className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600"
                  >
                    Safari
                  </a>
                </li>
                <li>
                  <a
                    href="https://help.opera.com/en/latest/web-preferences/#cookies"
                    className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600"
                  >
                    Opera
                  </a>
                </li>
              </ul>
              <p className="text-gray-700 dark:text-gray-300 mt-4">
                Usted tiene la posibilidad de evitar que Google Analytics
                utilice sus datos instalando el Complemento de Inhabilitación
                para Navegadores de Google Analytics. Mas información en el
                siguiente enlace:{" "}
                <a
                  href="https://tools.google.com/dlpage/gaoptout"
                  className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600"
                >
                  https://tools.google.com/dlpage/gaoptout
                </a>
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
                7. TRANSFERENCIAS DE TERCEROS:
              </h2>
              <p className="text-gray-700 dark:text-gray-300">
                Puede informarse de las transferencias a terceros países que, en
                su caso, realizan los terceros identificados en esta política de
                cookies en sus correspondientes políticas de privacidad (ver
                apartados 4 y 5)
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
                8. CAMBIAR LA CONFIGURACIÓN DE LAS COOKIES:
              </h2>
              <p className="text-gray-700 dark:text-gray-300">
                Si desea cambiar la configuración de las cookies, puede hacerlo
                pinchando{" "}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    // @ts-ignore
                    window.klaro.show();
                  }}
                  className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600"
                >
                  aquí
                </button>
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
