import React from "react";

export default function PrivacyPolicy() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800 py-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold text-gray-900 dark:text-white mb-8 text-center">
          Política de Privacidad
        </h1>

        <div className="bg-white dark:bg-gray-800 shadow-lg rounded-2xl overflow-hidden">
          <div className="px-6 py-8 sm:p-10 space-y-6 text-justify">
            <p className="text-gray-700 dark:text-gray-300">
              De conformidad con el Reglamento (UE) 2016/679 del Parlamento
              Europeo y del Consejo de 27 de abril de 2016 relativo a la
              protección de las personas físicas (RGPD) y la Ley Orgánica
              3/2018, de 5 de diciembre, de Protección de Datos Personales y
              garantía de los derechos digitales (LOPD-GDD), Akadamian, S.C. le
              informa sobre su política de privacidad en el tratamiento de los
              datos personales y le garantiza el cumplimiento de la normativa
              vigente en materia de protección de datos.
            </p>
            <p className="text-gray-700 dark:text-gray-300">
              La confidencialidad y la seguridad son valores primordiales de
              Akadamian, S.C. y, en consecuencia, asumimos el compromiso de
              garantizar la privacidad del usuario en todo momento y no recabar
              información innecesaria. A continuación, proporcionamos toda la
              información necesaria sobre la política de privacidad en relación
              con los datos personales que se recaban:
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mt-8 mb-4">
              1. RESPONSABLE DEL TRATAMIENTO
            </h2>
            <ul className="list-none space-y-2 text-gray-700 dark:text-gray-300 pl-4">
              <li>
                <span className="font-semibold">Responsable:</span> Akadamian,
                S.C.
              </li>
              <li>
                <span className="font-semibold">NIF:</span> J70901244
              </li>
              <li>
                <span className="font-semibold">Domicilio social:</span> Calle
                Torres Quevedo, 97 03204 Elx/Elche - (Alicante)
              </li>
              <li>
                <span className="font-semibold">
                  Dirección de correo electrónico:
                </span>{" "}
                <a
                  href="mailto:administracion@akadamian.com"
                  className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600"
                >
                  administracion@akadamian.com
                </a>
              </li>
              <li>
                <span className="font-semibold">Dirección web:</span>{" "}
                <a
                  href="/"
                  className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600"
                >
                  www.akadamian.com
                </a>
              </li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mt-8 mb-4">
              2. FINALIDAD, LEGITIMACIÓN Y CONSERVACIÓN DE LOS TRATAMIENTOS DE
              LOS DATOS ENVIADOS A TRAVÉS DE:
            </h2>
            <div className="pl-4">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                a. FORMULARIO DE CONTACTO:
              </h3>
              <ul className="list-none space-y-2 text-gray-700 dark:text-gray-300 pl-4">
                <li>
                  <span className="font-semibold">i. Finalidad:</span>{" "}
                  facilitarle un medio para que pueda ponerse en contacto con
                  Akadamian, S.C. y poder contestar a sus solicitudes de
                  información, así como enviarle comunicaciones de nuestros
                  productos, servicios y actividades, esto último solo si Usted
                  marca la casilla correspondiente otorgando el consentimiento
                  expreso.
                </li>
                <li>
                  <span className="font-semibold">ii. Legitimación:</span> el
                  consentimiento del usuario al solicitar información a través
                  del formulario de contacto y al marcar la casilla de
                  aceptación de envío de información (art. 6.1.a RGPD).
                </li>
                <li>
                  <span className="font-semibold">iii. Conservación:</span>{" "}
                  hasta la resolución de su solicitud por medio del formulario o
                  por correo electrónico, si no ha generado un nuevo
                  tratamiento, y, en caso de haber aceptado recibir envíos
                  comerciales, hasta que solicite su baja.
                </li>
              </ul>

              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2 mt-4">
                b. CORREOS ELECTRÓNICOS:
              </h3>
              <ul className="list-none space-y-2 text-gray-700 dark:text-gray-300 pl-4">
                <li>
                  <span className="font-semibold">i. Finalidad:</span> contestar
                  a sus solicitudes de información, atender sus peticiones y
                  responder sus consultas o dudas. En caso de recibir su
                  currículum vitae, sus datos personales y curriculares podrán
                  formar parte de nuestras bases de datos para participar en
                  nuestros procesos de selección.
                </li>
                <li>
                  <span className="font-semibold">ii. Legitimación:</span> el
                  consentimiento del usuario al solicitarnos información a
                  través de la dirección de correo electrónico o enviarnos sus
                  datos y currículum para participar en los procesos de
                  selección (art. 6.1.a RGPD).
                </li>
                <li>
                  <span className="font-semibold">iii. Conservación:</span>{" "}
                  hasta la resolución de la petición por correo electrónico, si
                  no ha generado un nuevo tratamiento. En el caso de recibir su
                  currículum, sus datos podrán ser conservados hasta que se
                  cubra la vacante del puesto solicitado.
                </li>
              </ul>
            </div>

            <p className="text-gray-700 dark:text-gray-300">
              En todo caso, los datos se conservarán durante el tiempo necesario
              para determinar posibles responsabilidades derivadas de la
              finalidad, o cuando haya una obligación legal.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mt-8 mb-4">
              3. COMUNICACIÓN DE DATOS:
            </h2>
            <p className="text-gray-700 dark:text-gray-300">
              Akadamian, S.C. puede comunicar sus datos a terceros con los que
              tenga suscrito un contrato de encargado de tratamiento cuando
              resulte imprescindible para el correcto cumplimiento de la
              relación contractual establecida con el interesado. Asimismo,
              podrán comunicarse cuando exista una obligación legal o judicial.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mt-8 mb-4">
              4. DERECHOS DE LOS USUARIOS EN RELACIÓN CON SUS DATOS PERSONALES:
            </h2>
            <p className="text-gray-700 dark:text-gray-300">
              Cualquier persona puede retirar su consentimiento en cualquier
              momento tras haberlo otorgado, sin necesidad de justificar el
              motivo. En ningún caso, la retirada de este consentimiento
              condicionará la ejecución del contrato o las relaciones generadas
              con anterioridad. Igualmente, se pueden ejercer los siguientes
              derechos:
            </p>
            <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 space-y-2">
              <li>Solicitar el acceso a los datos personales.</li>
              <li>Solicitar su rectificación por ser inexactos.</li>
              <li>
                Solicitar su supresión cuando, entre otros motivos, los datos ya
                no son necesarios para los fines para los que fueron recogidos.
              </li>
              <li>
                Solicitar la limitación de su tratamiento en determinadas
                circunstancias.
              </li>
              <li>
                Solicitar la oposición a su tratamiento por motivos relacionados
                con su situación particular.
              </li>
              <li>
                Solicitar la portabilidad de los datos en los casos previstos en
                la normativa.
              </li>
              <li>Otros derechos reconocidos en las normativas aplicables.</li>
            </ul>
            <p className="text-gray-700 dark:text-gray-300 mt-4">
              Podrá solicitar sus derechos mediante un escrito dirigido a
              Akadamian, S.C. enviando un correo electrónico a{" "}
              <a
                href="mailto:administracion@akadamian.com"
                className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600"
              >
                administracion@akadamian.com
              </a>{" "}
              o de manera presencial en Calle Torres Quevedo, 97 03204 Elx/Elche
              - (Alicante) indicando la referencia «Datos Personales»,
              especificando el derecho que se quiere ejercer y respecto a qué
              datos personales, acompañado de una fotocopia del DNI o documento
              de identificación equivalente.
            </p>
            <p className="text-gray-700 dark:text-gray-300 mt-4">
              También tiene derecho a presentar una reclamación ante la Agencia
              Española de Protección de Datos (
              <a
                href="https://www.aepd.es"
                className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600"
              >
                www.aepd.es
              </a>
              ) si considera que se ha cometido una infracción de la legislación
              en materia de protección de datos respecto al tratamiento de sus
              datos personales.
            </p>
            <p className="text-gray-700 dark:text-gray-300 mt-4">
              La comunicación de sus datos personales es un requisito necesario
              para poder gestionar la consulta a través de la web y para la
              ejecución del contrato, en su caso. La negación de facilitarnos
              sus datos personales impide que podamos atenderle.
            </p>
            <p className="text-gray-700 dark:text-gray-300 mt-4">
              No se toman decisiones automatizadas mediante procesos
              informáticos sin intervención humana, incluida la elaboración de
              perfiles, que produzcan efectos jurídicos.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mt-8 mb-4">
              5. PRESENCIA EN RRSS:
            </h2>
            <p className="text-gray-700 dark:text-gray-300">
              Akadamian, S.C. dispone de un perfil en las principales redes
              sociales con el nombre de akadamian_elx. El tratamiento que
              Akadamian, S.C. lleva a cabo de los seguidores en las redes
              sociales, será el que la red social correspondiente permita a los
              perfiles corporativos. Akadamian, S.C. es responsable del
              tratamiento de los datos publicados por el mismo, pero no de los
              datos publicados por otros usuarios. Akadamian, S.C. a través su
              perfil podrá informar a sus seguidores sobre sus productos,
              servicios, ofertas, realizar promociones, concursos, sorteos, así
              como prestar servicios de atención al cliente.
            </p>
            <p className="text-gray-700 dark:text-gray-300 mt-4">
              El usuario Seguidor de una RRSS debe aceptar las condiciones de
              uso y política de privacidad de la RRSS correspondiente.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mt-8 mb-4">
              6. SEGURIDAD DE LOS DATOS PERSONALES:
            </h2>
            <p className="text-gray-700 dark:text-gray-300">
              Akadamian, S.C. con el objetivo de salvaguardar sus datos
              personales, ha adoptado todas las medidas de índole técnica y
              organizativa necesarias para garantizar su seguridad y evitar su
              alteración, pérdida y tratamientos o accesos no autorizados,
              requeridas por la legislación vigente en protección de datos
              adecuadas al tipo de datos objeto del tratamiento.
            </p>
            <p className="text-gray-700 dark:text-gray-300 mt-4">
              No obstante, dado que las medidas de seguridad de los sistemas
              informáticos no son inexpugnables, pueden existir actuaciones
              dolosas por parte de terceros a pesar de que Akadamian, S.C. pone
              todos los medios a su alcance para evitar dichas actuaciones.
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mt-8 mb-4">
              7. COOKIES Y DIRECCIÓN IP:
            </h2>
            <p className="text-gray-700 dark:text-gray-300">
              Las cookies son ficheros enviados a un navegador por medio de un
              servidor web con el objeto de registrar la navegación del Usuario
              del Sitio Web, cuando el Usuario permita su recepción.
            </p>
            <p className="text-gray-700 dark:text-gray-300 mt-4">
              Este Sitio Web puede utilizar cookies propias o de terceros que
              pueden servir para identificar una sesión de usuarios y/o un
              equipo o dispositivo.
            </p>
            <p className="text-gray-700 dark:text-gray-300 mt-4">
              Las cookies recopilan la dirección IP del usuario, siendo Google
              el responsable del tratamiento de esta información, y no
              proporcionan por sí mismas el nombre de usuario ni ningún dato de
              carácter personal. Tampoco pueden leer datos de su disco duro ni
              archivos cookie creados por otros proveedores.
            </p>
            <p className="text-gray-700 dark:text-gray-300 mt-4">
              Una dirección IP es un número asignado automáticamente a un
              ordenador cuando este se conecta a internet. Al navegar por el
              Sitio Web, los servidores podrán detectar de manera automática la
              dirección IP y el nombre de dominio utilizados por el usuario.
              Toda esta información es registrada en ficheros de actividad del
              servidor que permiten el posterior procesamiento de los datos con
              el fin de obtener mediciones únicamente estadísticas acerca del
              número de impresiones de páginas, visitas realizadas a los
              servidores web, orden de visitas, puntos de acceso, etc.
            </p>
            <p className="text-gray-700 dark:text-gray-300 mt-4">
              Para más información, acceda a nuestra{" "}
              <a
                href="/politica-cookies"
                className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600"
              >
                Política de Cookies
              </a>
              .
            </p>

            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mt-8 mb-4">
              8. CAMBIOS EN LA POLÍTICA DE PRIVACIDAD:
            </h2>
            <p className="text-gray-700 dark:text-gray-300">
              Akadamian, S.C. se reserva el derecho a modificar la política de
              protección de datos de conformidad con la legislación vigente o
              con los criterios propios de la empresa. Cualquier modificación
              será publicada en esta página web.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
