import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

type NavLinksProps = {
  mobile?: boolean;
  isHomePage: boolean;
};

export default function NavLinks({
  mobile = false,
  isHomePage,
}: NavLinksProps) {
  const links = [
    { href: "#bienvenida", text: "Inicio" },
    { href: "#conocenos", text: "Conócenos" },
    { href: "#preguntas", text: "Preguntas frecuentes" },
    { href: "#contacto", text: "Contacto" },
  ];

  const baseClasses = mobile ? "block w-full text-left" : "inline-block";

  const linkClasses = `${baseClasses} px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-[#fb4414] dark:text-gray-300 dark:hover:text-white hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200`;

  const buttonClasses = `${baseClasses} px-3 py-2 rounded-md text-sm font-medium bg-gray-300 text-gray-600 dark:bg-gray-700 dark:text-gray-400 cursor-not-allowed transition-colors duration-200`;

  return (
    <div className={mobile ? "space-y-1" : "flex items-center space-x-1"}>
      {links.map((link) =>
        isHomePage ? (
          <HashLink
            key={link.href}
            to={link.href}
            className={linkClasses}
            smooth
          >
            {link.text}
          </HashLink>
        ) : (
          <Link key={link.href} to={`/${link.href}`} className={linkClasses}>
            {link.text}
          </Link>
        )
      )}
      <div className={`${baseClasses} relative group`}>
        <button disabled className={buttonClasses}>
          Akadamian online
        </button>
        <span className="absolute top-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap mt-1">
          Próximamente
        </span>
      </div>
    </div>
  );
}
