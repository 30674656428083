import React from "react";

export default function FAQ() {
  const faqs = [
    {
      question: "¿Qué servicios ofrece Akadamian?",
      answer: (
        <ul className="list-disc pl-5 space-y-2">
          <li>
            Clases de refuerzo escolar a todos los niveles (desde lectoescritura
            y primaria hasta bachillerato)
          </li>
          <li>
            Preparación de pruebas de acceso para grado medio, superior y
            selectividad
          </li>
          <li>Preparación de certificados de idiomas (valenciano e inglés)</li>
          <li>
            Apartado clínico (logopedia, psicología y fisioterapia de atención
            temprana) -{" "}
            <span className="text-[#fb4414] dark:text-[#ff6b3d]">
              próximamente
            </span>{" "}
            -
          </li>
          <li>Clases de alfabetización informática</li>
          <li>Sala de estudio</li>
          <li>
            Clases online -{" "}
            <span className="text-[#fb4414] dark:text-[#ff6b3d]">
              próximamente
            </span>{" "}
            -
          </li>
        </ul>
      ),
    },
    {
      question: "¿En qué consistirán las clases online?",
      answer: (
        <ul className="list-disc pl-5 space-y-2">
          <li>
            Uso de un sistema novedoso basado en el metaverso para una mayor
            inmersión adaptada a todos los niveles
          </li>
          <li>
            Clases de repaso grabadas junto con explicaciones y ejercicios
            prácticos, así como, resolución de las dudas más frecuentes con
            respecto al tema tratado.
          </li>
          <li>Lecciones en directo a través de Google Meet</li>
        </ul>
      ),
    },
    {
      question: "¿Qué horario tenéis?",
      answer:
        "La parte online estará habilitada 24h todos los días del año. En cuanto a las clases presenciales dependerán del nivel, edad, asignatura y curso del alumno. El horario habitual es de lunes a viernes de 15:30h. a 21:30h. Además disponemos de un horario de mañanas reservado para cursos extras de idiomas, informática y preparación profesional.",
    },
    {
      question: "¿Cómo se imparten las clases presenciales?",
      answer:
        "Se imparten en grupos reducidos de alumnos que se encuentren en el mismo curso y asignatura. Además se proporcionará material para practicar lo aprendido",
    },
    {
      question: "¿Qué ocurre si tengo alguna duda en casa?",
      answer:
        "Puedes enviar cualquier duda que te surja en cualquier momento vía WhatsApp o email y un profesor de Akadamian lo atenderá en cuanto le sea posible. En Akadamian la formación siempre es continua y tratamos de evitar cualquier bloqueo en el aprendizaje.",
    },
  ];

  return (
    <section id="preguntas" className="py-16 bg-gray-100 dark:bg-[#2a1f3d]">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-[#fb4414] dark:text-white mb-8 text-center">
          Preguntas frecuentes
        </h2>
        <div className="flex justify-center mb-8">
          <div className="w-16 h-1 bg-[#fb4414] dark:bg-white rounded-full"></div>
        </div>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>
    </section>
  );
}

type FAQItemProps = {
  question: string;
  answer: string | React.ReactNode;
};

function FAQItem({ question, answer }: FAQItemProps) {
  return (
    <details className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
      <summary className="font-semibold text-[#fb4414] dark:text-white cursor-pointer text-lg">
        {question}
      </summary>
      <div className="mt-4 text-gray-700 dark:text-gray-300 text-justify">
        {typeof answer === "string" ? <p>{answer}</p> : answer}
      </div>
    </details>
  );
}
